import escapeHTML from 'escape-html';

// DOM
export const DOM_ELEMENT_TYPE = 1;
export const DOM_TEXT_TYPE = 3;

// Reconciling
export const NO_DIRTY_NODES = 0;
export const HAS_DIRTY_NODES = 1;
export const FULL_RECONCILE = 2;

// Text node modes
export const IS_NORMAL = 0;
export const IS_TOKEN = 1;
export const IS_SEGMENTED = 2;

// Text node formatting
export const IS_BOLD = 1;
export const IS_ITALIC = 1 << 1;
export const IS_STRIKETHROUGH = 1 << 2;
export const IS_UNDERLINE = 1 << 3;
export const IS_CODE = 1 << 4;
export const IS_SUBSCRIPT = 1 << 5;
export const IS_SUPERSCRIPT = 1 << 6;
export const IS_HIGHLIGHT = 1 << 7;

export const IS_ALL_FORMATTING =
    IS_BOLD | IS_ITALIC | IS_STRIKETHROUGH | IS_UNDERLINE | IS_CODE | IS_SUBSCRIPT | IS_SUPERSCRIPT | IS_HIGHLIGHT;

export const IS_DIRECTIONLESS = 1;
export const IS_UNMERGEABLE = 1 << 1;

// Element node formatting
export const IS_ALIGN_LEFT = 1;
export const IS_ALIGN_CENTER = 2;
export const IS_ALIGN_RIGHT = 3;
export const IS_ALIGN_JUSTIFY = 4;
export const IS_ALIGN_START = 5;
export const IS_ALIGN_END = 6;

export const TEXT_TYPE_TO_FORMAT = {
    bold: IS_BOLD,
    code: IS_CODE,
    italic: IS_ITALIC,
    strikethrough: IS_STRIKETHROUGH,
    subscript: IS_SUBSCRIPT,
    superscript: IS_SUPERSCRIPT,
    underline: IS_UNDERLINE,
};

const MAX_UNIQUE_NESTED_LIST_DECORATIONS = {
    bullet: 3,
    number: 5,
}

interface Node {
    type: string;
    text?: string;
    format?: number;
    children?: Node[];
    fields?: Record<string, any>;
    listType?: string;
    tag?: string;
    footnoteId?: number;
}

function getLinkForPage(doc: any): string | undefined {
    return doc?.value?.path || doc?.value?.url;
}

export default function lexicalSerialize(children: Node[] | null, listNestingLevel = 0): string {
    if (!children) return '';
    const route = useRoute(); // Adjust type if necessary

    return children
        .map((node) => {
            if (node.type === 'text') {
                let text = `${escapeHTML(node.text || '')}`;

                if (node.format && (node.format & IS_BOLD)) {
                    text = `<strong>${text}</strong>`;
                }
                if (node.format && (node.format & IS_ITALIC)) {
                    text = `<em>${text}</em>`;
                }
                if (node.format && (node.format & IS_STRIKETHROUGH)) {
                    text = `<span class="line-through">${text}</span>`;
                }
                if (node.format && (node.format & IS_UNDERLINE)) {
                    text = `<span class="underline">${text}</span>`;
                }
                if (node.format && (node.format & IS_CODE)) {
                    text = `<code>${text}</code>`;
                }
                if (node.format && (node.format & IS_SUBSCRIPT)) {
                    text = `<sub>${text}</sub>`;
                }
                if (node.format && (node.format & IS_SUPERSCRIPT)) {
                    text = `<sup>${text}</sup>`;
                }

                return text;
            }

            if (!node) {
                return null;
            }

            if (node.type === 'list') {
                listNestingLevel++

                if (listNestingLevel > MAX_UNIQUE_NESTED_LIST_DECORATIONS[node.listType as 'bullet' | 'number']) {
                    if (node.listType === 'number') {
                        listNestingLevel = 1
                    } else {
                        listNestingLevel = MAX_UNIQUE_NESTED_LIST_DECORATIONS.bullet
                    }
                }
            }

            const serializedChildren = node.children ? lexicalSerialize(node.children, listNestingLevel) : null;

            switch (node.type) {
                case 'linebreak':
                    return `<br>`;
                case 'link':
                    const attributes = node.fields || {};
                    if (attributes.linkType === 'custom') {
                        return `<a href="${attributes.url}"${attributes.newTab ? ' target="_blank"' : ''} rel="${attributes.rel ?? ''
                            }${attributes.sponsored ? ' sponsored' : ''}${attributes.nofollow ? ' nofollow' : ''}">${serializedChildren}</a>`;
                    } else {
                        return `<a href="${getLinkForPage(attributes.doc)}" ${attributes.newTab ? ' target="_blank"' : ''
                            } rel="${attributes.rel ?? ''}${attributes.sponsored ? ' sponsored' : ''}${attributes.nofollow ? ' nofollow' : ''
                            }">${serializedChildren}</a>`;
                    }
                case 'list': {
                    const nestedListDecorationClassName = node.listType === 'bullet' ? `ul${listNestingLevel}` : `ol${listNestingLevel}`;

                    return node.listType === 'bullet'
                        ? `<ul class="mb-4 pl-8 ${nestedListDecorationClassName}">${serializedChildren}</ul>`
                        : `<ol class="mb-4 pl-8 ${nestedListDecorationClassName}">${serializedChildren}</ol>`;
                }
                case 'listitem':
                    const hasNestedList = node.children?.some((child) => child.type === 'list') ?? false
                    const classString = hasNestedList ? ' class="nested-list-item"' : ''

                    return `<li${classString}>${serializedChildren}</li$>`;
                case 'heading':
                    return `<${node.tag}>${serializedChildren}</${node.tag}>`;
                case 'quote':
                    return `<blockquote>${serializedChildren}</blockquote>`;
                case 'footnote':
                    return `<sup class="footnote">${node.footnoteId}</sup>`;
                case 'block':
                    return `${node.fields?.data?.html || ''}`;
                default:
                    return serializedChildren ? `<p>${serializedChildren}</p>` : '<br />';
            }
        })
        .filter((node) => node !== null)
        .join('');
}
